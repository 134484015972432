import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <section id="footer">
      <div class="bg-color-primary-dark text-gray-400">
        <div class="container py-10 mx-auto">
          <div class="flex justify-between pt-4 border-color-gray">
            <p>&copy; 2023 Eternal</p>

            <div className="flex space-x-4">
              <Link className="font-light text-sm hover:cursor-pointer" to="/tos">
                Privacy
              </Link>
              <Link
                className="font-light text-sm hover:cursor-pointer"
                to="https://discord.gg/YrGj3FbjAv">
                Support
              </Link>
              <Link className="font-light text-sm hover:cursor-pointer" to="/tos">
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
