import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useParams } from 'react-router-dom';
import './sellix.css';

const data = {
  prices: [34.99, 124.99],
  product_ids: ['64496886407aa', '64493c56acb4e'],
};

const ProductView = () => {
  let { productName } = useParams();

  const [activeTab, setActiveTab] = useState('details');
  const [selectedType, setSelectedType] = useState(0);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <section id="product" className="relative">
      <div class="blob1"></div>
      <div class="blob2"></div>
      <div class="container py-24 mx-auto min-h-screen min-w-full">
        <div class="text-center m-auto mb-20 md:w-1/2">
          <h4 class="font-bold text-color-secondary mb-4">PRODUCT</h4>
          <h1 class="title mb-8 capitalize">{productName}</h1>
        </div>

        <div className="flex flex-col space-y-5">
          <div class="md:flex min-w-full items-start justify-center  2xl:px-20 md:px-6 px-4">
            <div class="xl:w-2/5 lg:w-2/5 h-max w-80 md:block hidden ">
              <iframe
                src="https://www.youtube.com/embed/1KZYdy-qcXM"
                className="w-full h-96"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
            <div class="md:hidden">
              <iframe
                src="https://www.youtube.com/embed/1KZYdy-qcXM"
                className="w-full h-96"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
              <div class="flex items-center justify-between mt-3 space-x-4 md:space-x-0"></div>
            </div>

            <div class="xl:w-2/5 md:w-1/2 lg:ml-8 md:ml-6 flex flex-col space-y-4">
              <div class=" md:mt-0 mt-6 bg-color-primary-dark rounded-lg shadow-xl px-1 py-1 sm:px-4 min-h-96">
                <div class="text-sm font-medium text-center border-b text-zinc-400 border-[#27272A]">
                  <ul class="flex -mb-px">
                    <li class="mr-2">
                      <button
                        onClick={() => handleTabClick('details')}
                        class={`inline-block p-4  ${
                          activeTab == 'details'
                            ? 'border-color-secondary rounded-t-lg active border-b-2'
                            : ''
                        } font-medium uppercase leading-tight text-xs`}>
                        Details
                      </button>
                    </li>
                    <li class="mr-2">
                      <button
                        onClick={() => handleTabClick('features')}
                        class={`inline-block p-4  ${
                          activeTab == 'features'
                            ? 'border-color-secondary rounded-t-lg active border-b-2'
                            : ''
                        } font-medium uppercase leading-tight text-xs`}
                        aria-current="page">
                        Features
                      </button>
                    </li>
                    <li class="mr-2">
                      <button
                        onClick={() => handleTabClick('requirements')}
                        class={`inline-block p-4  ${
                          activeTab == 'requirements'
                            ? 'border-color-secondary rounded-t-lg active border-b-2'
                            : ''
                        } font-medium uppercase leading-tight text-xs`}>
                        Requirements
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="px-2 md:px-0 pb-4">
                  {activeTab == 'details' && (
                    <p class="xl:pr-48 text-sm leading-relaxed font-segoe text-zinc-300 mt-4 px-2">
                      A semi-private Fortnite cheat that offers the perfect balance between legit
                      and rage cheating. With a user-friendly interface and extensive customization
                      options, our cheat provides an enjoyable and seamless experience. Our primary
                      focus is on bypassing the anticheat system to ensure a nice cheating
                      experience.
                    </p>
                  )}

                  {activeTab == 'features' && (
                    <p class="xl:pr-48 text-sm leading-relaxed font-segoe text-zinc-300 mt-4">
                      <ul class="list-disc pl-6">
                        <li>Aimbot</li>
                        <div className="pl-3 mt-1 mb-2">
                          <li>Configurable for every weapon type</li>
                          <li>Aim FOV</li>
                          <li>Aim Speed</li>
                          <li>Customizable aim points. You can select multiple.</li>
                          <li>Max distance</li>
                        </div>
                        <li>Triggerbot</li>
                        <div className="pl-3 mt-1 mb-2">
                          <li>Configurable for every weapon type</li>
                          <li>Delay (0-100ms)</li>
                        </div>
                        <li>Visuals</li>
                        <div className="pl-3 mt-1 mb-2">
                          <li>Player ESP</li>
                          <li>Offscreen ESP</li>
                        </div>
                        <li>Configs</li>
                        <li>
                          Stream Mode (Hides visuals and menu from being displayed from Recording
                          tools and screenshots)
                        </li>
                      </ul>
                    </p>
                  )}

                  {activeTab == 'requirements' && (
                    <p class="xl:pr-48 text-sm leading-relaxed font-segoe text-zinc-300 mt-4">
                      <ul class="list-disc pl-6">
                        <li>Support</li>
                        <div className="pl-3 mt-1 mb-2">
                          <li>Windows 7-11</li>
                          <li>Intel & AMD both supported</li>
                        </div>
                      </ul>
                    </p>
                  )}
                </div>
              </div>
              <div class="md:mt-0 mt-6 rounded-lg shadow-xl bg-color-primary-dark px-1 py-1 sm:px-4 min-h-96 ">
                <div class="px-8 py-4">
                  <ul class="max-w-96 pb-4 sm:pb-0">
                    <div className="flex justify-between pt-3 mb-4">
                      <p className="font-light text-sm text-zinc-500">Price</p>

                      <h1 className="font-black text-2xl">{data.prices[selectedType]}$</h1>
                    </div>
                    <li class="flex flex-col sm:py-4 space-y-6">
                      <button
                        class={`px-8 py-5 rounded-lg border  ${
                          selectedType === 0 ? 'border-color-secondary' : 'border-[#252829]'
                        } ease-in duration-500 bg-[#1A1D1E] `}
                        onClick={() => {
                          setSelectedType(0);
                        }}>
                        <div className="flex justify-between text-sm md:text-base">
                          <p className="font-segoe">1 Week</p>
                          <p className="font-segoe flex space-x-1">
                            <p>34.99</p>
                            <p className="text-color-secondary">$</p>
                          </p>
                        </div>
                      </button>
                      <button
                        class={`px-8 py-5 rounded-lg border  ${
                          selectedType === 1 ? 'border-color-secondary' : 'border-[#252829]'
                        } ease-in duration-500 bg-[#1A1D1E] `}
                        onClick={() => {
                          setSelectedType(1);
                        }}>
                        <div className="flex justify-between text-sm md:text-base">
                          <p className="font-segoe">1 Month</p>
                          <p className="font-segoe flex space-x-1">
                            <p>124.99</p>
                            <p className="text-color-secondary">$</p>
                          </p>
                        </div>
                      </button>
                      <button
                        class={`px-8 py-5 rounded-lg bg-color-secondary flex justify-center font-bold`}
                        data-sellix-product={data.product_ids[selectedType]}
                        // data-sellix-css="https://cdn.sellix.io/static/css/custom-embed-styles.css"
                        // type="submit"
                        alt="Buy Now with sellix.io">
                        Checkout
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ProductView.propTypes = {
  name: PropTypes.string.isRequired,
};
export default ProductView;
