import React, { useEffect, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-scroll';

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className={`sticky top-0 z-50 transition-colors border-color-gray ${
        isScrolled ? 'bg-color-primary-dark border-b' : ''
      }`}>
      <nav class="container flex justify-between items-center">
        <div className="flex items-center">
          <a href="/" className="py-5 text-white font-bold text-2xl flex">
            ETERNAL.<p className="text-color-secondary">CX</p>
          </a>
        </div>

        <div className="hidden lg:flex items-center space-x-8">
          {/* <Link
            className="text-gray-400 hover:text-white ease-in duration-200 hover:cursor-pointer"
            activeClass="active"
            href="/"
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={closeMenu}>
            Home
          </Link> */}
          <NavHashLink
            className="text-gray-400 hover:text-white ease-in duration-200 hover:cursor-pointer"
            activeClass="active"
            href="/"
            to="/#home"
            smooth={true}
            offset={-70}
            duration={500}
            onClick={closeMenu}>
            Home
          </NavHashLink>
          <NavHashLink
            className="text-gray-400 mr-4 hover:text-white ease-in duration-200 hover:cursor-pointer"
            activeClass="active"
            to="/#products"
            smooth={true}
            offset={-70}
            duration={500}
            onClick={closeMenu}>
            Shop
          </NavHashLink>
          <NavHashLink
            className="text-gray-400 mr-4 hover:text-white ease-in duration-200 hover:cursor-pointer"
            activeClass="active"
            to="/status"
            smooth={true}
            offset={-70}
            duration={500}
            onClick={closeMenu}>
            Status
          </NavHashLink>
          <button
            onClick={() => window.open('https://discord.gg/YrGj3FbjAv', '_blank')}
            class="bg-[#7289da] py-3 px-7 rounded-full text-sm">
            Discord
          </button>
        </div>

        <div className="lg:hidden cursor-pointer z-50" onClick={handleMenuToggle}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
        {isMenuOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-color-primary-dark z-40">
            <div className="flex flex-col justify-center items-center h-full">
              <NavHashLink
                className="text-gray-400 hover:text-white ease-in duration-200 mb-8"
                activeClass="active"
                href="/"
                to="/#home"
                smooth={true}
                offset={-70}
                duration={500}
                onClick={closeMenu}>
                Home
              </NavHashLink>
              <NavHashLink
                className="text-gray-400 hover:text-white ease-in duration-200 mb-8"
                activeClass="active"
                href="/"
                to="/#products"
                smooth={true}
                offset={-70}
                duration={500}
                onClick={closeMenu}>
                Shop
              </NavHashLink>
              <NavHashLink
                className="text-gray-400 hover:text-white ease-in duration-200 mb-8"
                activeClass="active"
                to="/status"
                smooth={true}
                offset={-70}
                duration={500}
                onClick={closeMenu}>
                Status
              </NavHashLink>

              <button
                class="bg-[#7289da] py-4 px-9 rounded-full text-sm"
                onClick={() => window.open('https://discord.gg/YrGj3FbjAv', '_blank')}>
                Discord
              </button>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}

export default Navbar;
