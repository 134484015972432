import React from 'react';

export const Status = () => {
  return (
    <section id="status-component" className="relative">
      <div class="blob1"></div>
      <div class="blob2"></div>
      <div class="container py-24 mx-auto min-h-screen min-w-full">
        <div class="text-center m-auto mb-20 md:w-1/2">
          <h4 class="font-bold text-color-secondary mb-4">STATUS</h4>
          <h1 class="title mb-8">Current Status</h1>
        </div>
        <div className="max-w-xl mx-auto">
          <div class="grid items-center max-w-[800px] mx-auto">
            <ul class="divide-y divide-color-card-hover bg-color-primary-dark p-6 rounded-xl shadow-lg">
              <li class="py-3 sm:py-4">
                <div class="flex justify-between items-center space-x-4">
                  <p class="text-sm font-medium text-gray-900 truncate dark:text-white">Fortnite</p>
                  <p class="text-sm truncate text-green-500">UNDETECTED</p>
                </div>
              </li>
              <li class="py-3 sm:py-4">
                <div class="flex justify-between items-center space-x-4">
                  <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                    Tower Of Fantasy
                  </p>
                  <p class="text-sm truncate text-green-500">UNDETECTED</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
