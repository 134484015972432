import React from 'react';

export const ToS = () => {
  return (
    <section id="status-component" className="relative">
      <div class="blob1"></div>
      <div class="blob2"></div>

      <div class="container py-24 mx-auto min-h-screen min-w-full">
        <div class="text-center m-auto mb-20 md:w-1/2">
          <h4 class="font-bold text-color-secondary mb-4">TOS</h4>
          <h1 class="title mb-8">Terms of Service</h1>
          <div className="text-left">
            <p className="mb-3">
              • By using our services, you agree to the following terms and conditions, and take
              full responsibility for any applicable local laws.
            </p>
            {/* <p className="mb-3">
              • We are not responsible for any bans or suspensions in the game.
            </p> */}
            <p className="mb-3">
              • Our services are provided without any warranties, including the implied warranty of
              merchantability or fitness for a particular purpose. This means that we are not
              responsible for any damages caused by using our services, and we do not offer any
              compensation or refunds.
            </p>
            <p className="mb-3">
              • You are not allowed to attempt to debug or reverse engineer our products.
            </p>
            <p className="mb-3">
              • You are not allowed to share or resell our products without permission. Doing so may
              result in the deactivation of all of your keys.
            </p>
            <p className="mb-3">
              • You are only allowed to use a payment method that you are authorized to use. Any
              unauthorized use of payment methods, such as hacked or stolen bank accounts, is
              strictly prohibited and will result in immediate account termination. We take
              fraudulent activity very seriously and reserve the right to report any such activity
              to the relevant authorities, including but not limited to the Federal Bureau of
              Investigation (FBI).
            </p>
            <p className="mb-3">
              • Disputing a payment will result in permanent account disablement.
            </p>
            <p className="mb-3">
              • If you do not agree to these terms, you are prohibited from using our services.
            </p>
            <p className="mb-3">
              • If you sell our products without verification or permission, you will not be
              permitted to be associated with us in the future.
            </p>
            <p className="mb-3">
              • By redeeming a key, you consent to any issues you may encounter.Compensation may
              only be provided if the issue is on our side.
            </p>
          </div>
        </div>
        <div className="max-w-xl mx-auto">
          <div class="max-w-[800px] mx-auto"></div>
        </div>
      </div>
    </section>
  );
};
