import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Navbar from './components/Navbar.js';

import { Home } from './pages/Home.js';
import { Status } from './pages/Status.js';
import { Footer } from './components/Footer.js';
import ProductView from './components/ProductView.js';
import { ToS } from './pages/ToS.js';
import Cookies from 'js-cookie';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

function App() {
  const cookieId = Cookies.get('id');
  if (!cookieId) {
    const newcookieId = uuidv4();
    Cookies.set('id', newcookieId);
    LogRocket.identify(newcookieId);
  } else {
    LogRocket.identify(cookieId);
  }

  LogRocket.init('1ivbgs/eternal');
  setupLogRocketReact(LogRocket);

  return (
    <>
      <BrowserRouter>
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/status" element={<Status />} />
          <Route path="/products/:productName" element={<ProductView />} />
          <Route path="/tos" element={<ToS />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
