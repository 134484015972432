import React from 'react';
import './style.css';
import loaderImage from '../assets/Loader.png';
import fortniteImage from '../assets/fortnite.jpg';
import tofImage from '../assets/tof.jpg';
import soloLevelingImage from '../assets/sla.png';
import wutheringWavesImage from '../assets/wuthering-waves.jpg'

import {
  FaRocket,
  FaDesktop,
  FaCheckCircle,
  FaLifeRing,
  FaShieldAlt,
  FaSyncAlt,
} from 'react-icons/fa';
import { Link } from 'react-scroll';

export const Home = () => {
  return (
    <>
      <section id="home" className="relative">
        <div className="blob1"></div>
        <div className="blob2"></div>
        <div className="container py-24 mx-auto min-h-screen">
          <div className="flex flex-col items-center z-20 md:flex-row">
            <div className="lg:flex-grow lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
              <h1 className="text-3xl md:text-4xl font-bold leading-snug text-white mb-4 capitalize">
                Most premium cheats
              </h1>
              <p className="leading-relaxed mb-10 text-gray-300 max-w-2xl">
                Enhance your gameplay with our premium cheats, backed by excellent support and
                frequent updates.
              </p>
              <div className="flex space-x-4">
                <Link
                  activeclassName="active"
                  href="/"
                  to="products"
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="bg-color-secondary px-9 py-3 rounded-md capitalize font-bold hover:opacity-80 ease-in duration-200 text-white">
                  Our Products
                </Link>
              </div>
            </div>
            <div className="relative group">
              {/* md:rotate-[2deg] */}
              <div className="absolute -inset-0.5 bg-gradient-to-r from-pink-600 to-purple-600 rounded-lg blur opacity-75  group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
              <button className="relative bg-color-primary-light rounded-lg leading-none flex items-center divide-x  divide-gray-600">
                <img
                  className="object-cover object-center drop-shadow-2xl "
                  alt="hero"
                  src={loaderImage}
                />
              </button>
            </div>
          </div>
        </div>
      </section>
      <section id="features" className="bg-color-primary-light">
        <div className="container py-20">
          <div className="text-center m-auto mb-20 md:w-1/2">
            <h4 className="font-bold text-color-secondary mb-4">FEATURES</h4>
            <h1 className="title mb-8">Why Choose Us?</h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="relative flex items-start justify-between rounded-xl border bg-[#151617] border-[#1B1C1D] p-4 shadow-xl sm:p-6 lg:p-8">
              <div className="pt-4 text-color-white">
                <FaLifeRing className="h-8 w-8 sm:h-10 sm:w-10 " />

                <h3 className="mt-4 text-lg font-bold text-color-secondary sm:text-xl">
                  24/7 Support
                </h3>
                <p className="text-gray-300 mt-2 text-sm font-extralight">
                  Our team is always ready to assist you whenever you need help or have a question.
                </p>
              </div>
            </div>
            <div className="relative flex items-start justify-between rounded-xl border bg-[#151617] border-[#1B1C1D] p-4 shadow-xl sm:p-6 lg:p-8">
              <div className="pt-4 text-color-white">
                <FaShieldAlt className="h-8 w-8 sm:h-10 sm:w-10" />

                <h3 className="mt-4 text-lg font-bold text-color-secondary sm:text-xl">
                  Undetected Cheats
                </h3>
                <p className="text-gray-300 mt-2 text-sm font-extralight">
                  Our cheats are designed to be undetected and safe to use, giving you the advantage
                  you need.
                </p>
              </div>
            </div>
            <div className="relative flex items-start justify-between rounded-xl border bg-[#151617] border-[#1B1C1D] p-4 shadow-xl sm:p-6 lg:p-8">
              <div className="pt-4 text-color-white">
                <FaSyncAlt className="h-8 w-8 sm:h-10 sm:w-10" />

                <h3 className="mt-4 text-lg font-bold text-color-secondary sm:text-xl">
                  Frequent Updates
                </h3>
                <p className="text-gray-300 mt-2 text-sm font-extralight">
                  We regularly update our cheats to ensure they continue to work and stay
                  undetected.
                </p>
              </div>
            </div>

            <div className="relative flex items-start justify-between rounded-xl border bg-[#151617] border-[#1B1C1D] p-4 shadow-xl sm:p-6 lg:p-8">
              <div className="pt-4 text-color-white">
                <FaCheckCircle className="h-8 w-8 sm:h-10 sm:w-10" />

                <h3 className="mt-4 text-lg font-bold text-color-secondary sm:text-xl">
                  Easy to Use
                </h3>
                <p className="text-gray-300 mt-2 text-sm font-extralight">
                  Our cheats are easy to use and designed to make your experience as smooth as
                  possible.
                </p>
              </div>
            </div>
            <div className="relative flex items-start justify-between rounded-xl border bg-[#151617] border-[#1B1C1D] p-4 shadow-xl sm:p-6 lg:p-8">
              <div className="pt-4 text-color-white">
                <FaDesktop className="h-8 w-8 sm:h-10 sm:w-10" />

                <h3 className="mt-4 text-lg font-bold text-color-secondary sm:text-xl">
                  Clean User Interface
                </h3>
                <p className="text-gray-300 mt-2 text-sm font-extralight">
                  The clean and intuitive user interface makes it easy to navigate and use the
                  cheats.
                </p>
              </div>
            </div>
            <div className="relative flex items-start justify-between rounded-xl border bg-[#151617] border-[#1B1C1D] p-4 shadow-xl sm:p-6 lg:p-8">
              <div className="pt-4 text-color-white">
                <FaRocket className="h-8 w-8 sm:h-10 sm:w-10" />

                <h3 className="mt-4 text-lg font-bold text-color-secondary sm:text-xl">
                  Instant Access
                </h3>
                <p className="text-gray-300 mt-2 text-sm font-extralight">
                  Our cheats are available for instant access without the need for an ID or any
                  other information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="products" className="">
        <div className="container py-20">
          <div className="text-center m-auto mb-20 md:w-1/2">
            <h4 className="font-bold text-color-secondary mb-4">PRODUCTS</h4>
            <h1 className="title mb-8">Game Categories</h1>
          </div>

          <div className="flex justify-center -m-4 space-x-4 ">
            <div className="p-4 w-1/3 ">
              <div className="relative h-48 md:h-[40vh] lg:h-[40vh] rounded overflow-hidden shadow-md">
                <img
                  alt="Fortnite"
                  className="object-cover object-center w-full h-full block rounded-md aspect-ratio-3/4"
                  src={fortniteImage}
                />
                <a
                  href="/products/fortnite"
                  className="absolute inset-0 flex items-center justify-center text-white font-bold md:text-2xl bg-color-primary-dark opacity-0 hover:opacity-75 transition duration-300 ">
                  View Products
                </a>
              </div>
              <div className="mt-4">
                <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">FORTNITE</h3>
              </div>
            </div>

            <div className="p-4 w-1/3">
              <div className="relative h-48 md:h-[40vh] lg:h-[40vh] rounded overflow-hidden shadow-md">
                <img
                  alt="Tower of fantasy"
                  className="object-cover object-center w-full h-full block rounded-md aspect-ratio-3/4"
                  src={tofImage}
                />
                <a
                  href="https://eternalcheats.mysellix.io/"
                  className="absolute inset-0 flex items-center justify-center text-white font-bold md:text-2xl bg-color-primary-dark opacity-0 hover:opacity-75 transition duration-300 ">
                  View Products
                </a>
              </div>
              <div className="mt-4">
                <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                  TOWER OF FANTASY
                </h3>
              </div>
            </div>

            <div className="p-4 w-1/3">
              <div className="relative h-48 md:h-[40vh] lg:h-[40vh] rounded overflow-hidden shadow-md">
                <img
                  alt="Solo Leveling: Arise"
                  className="object-cover object-center w-full h-full block rounded-md aspect-ratio-3/4"
                  src={soloLevelingImage}
                />
                <a
                  href="https://eternalcheats.mysellix.io/"
                  className="absolute inset-0 flex items-center justify-center text-white font-bold md:text-2xl bg-color-primary-dark opacity-0 hover:opacity-75 transition duration-300 ">
                  View Products
                </a>
              </div>
              <div className="mt-4">
                <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                  SOLO LEVELING: ARISE
                </h3>
              </div>
            </div>
            
            <div className="p-4 w-1/3">
              <div className="relative h-48 md:h-[40vh] lg:h-[40vh] rounded overflow-hidden shadow-md">
                <img
                  alt="Wuthering Waves"
                  className="object-cover object-center w-full h-full block rounded-md aspect-ratio-3/4"
                  src={wutheringWavesImage}
                />
                <a
                  href="https://eternalcheats.mysellix.io/"
                  className="absolute inset-0 flex items-center justify-center text-white font-bold md:text-2xl bg-color-primary-dark opacity-0 hover:opacity-75 transition duration-300 ">
                  View Products
                </a>
              </div>
              <div className="mt-4">
                <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                  Wuthering Waves
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="community" className="bg-color-primary-light">
        <div className="container py-20">
          <div className="text-center m-auto mb-20 md:w-1/2">
            <h4 className="font-bold text-color-secondary mb-4">COMMUNITY</h4>
            <h1 className="font-bold text-2xl mb-8">Join the Eternal Community on Discord!</h1>
            <button
              onClick={() => window.open('https://discord.gg/YrGj3FbjAv', '_blank')}
              className="bg-[#7289da] py-4 px-12 rounded-full">
              Join Discord
            </button>
          </div>
        </div>
      </section>
    </>
  );
};
